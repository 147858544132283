$(document).ready(function () {
    Dropzone.autoDiscover = false;
    initialisers(document);
});

var initialisers = function (html) {
    var $html = $(html);

    initialiseCroppieIfPresent();

    initPhotoSwipeFromDOM('.gallery a.gallery-thumb');

    $html.find('.results-text-copy').click(CopyResultsTextToClipboard);

    $html.find(".select2").select2({ theme: "bootstrap", width: '100%' });

    $html.find('.select2-tags').select2({ theme: 'bootstrap', width: '100%', tags: true });

    $html.find('form[action^="/member/login"]').submit(function (e) {
        $button = $(this).find(':submit');
        $button.prop('disabled', true);
        setTimeout(function () {
            $button.prop('disabled', false);
        }, 2500);
    });

    $html.find('.basket-submission').on('submit', function (e) {
        ga('send', 'event', {
            eventCategory: 'Outbound Link',
            eventAction: 'click',
            eventLabel: 'paypal.com',
            transport: 'beacon'
        });

        ga('send', {
            hitType: 'pageview',
            page: location.pathname + '?ProceedToCheckout=true'
        });
    });

    $html.find('.basket-quantity').on('select2:select', handleBasketQuantityChange);

    $html.find('.basket-delete-line').click(handleBasketDelete);

    $html.find('.judge-row').click(function (e) {
        var judgingForm = document.getElementById('judging-form');
        var goToInput = document.createElement('input');
        goToInput.type = 'hidden'
        goToInput.name = 'GoTo';
        goToInput.value = e.target.closest('tr').dataset.entryId;
        judgingForm.appendChild(goToInput);
        judgingForm.submit();
    });

    $html.find('.back-button').click(function (e) {
        window.history.back();
    });

    $html.find(".datepicker").datetimepicker({
        format: 'DD/MM/YYYY',
        widgetPositioning: { vertical: 'bottom' }
    });

    $html.find(".datepickerbirthday").datetimepicker({
        viewMode: 'years',
        format: 'YYYY',
        widgetPositioning: { vertical: 'bottom' }
    });

    $html.find('.ajax-paging').ajaxPaging();

    $html.find('.crud-page').modalCrud();

    $html.find('.upload-choice').scrollToTab();

    $html.find('input[data-provider]').oembedProvider('#UploadVideo_Video');

    $html.find('#UploadVideo_Video').change(function () {
        $('#uploaded-video').html(decodeURIComponent($(this).val()));
    });

    //$('a.gs-gallery').prettyPhoto({
    //    social_tools: false,
    //    gallery_markup: '<div class="pp_gallery"></div>'
    //});

    $('#UploadVideo_HorseId').on('select2:select', function (e) {
        var $horseNameInput = $('#UploadVideo_HorseName')
        if ($horseNameInput.length) {
            $horseNameInput.val(e.params.data.text);
        }
    });

    $('#ParticipantNames').on('select2:select', function (e) {
        var $participantNameInput = $('#ParticipantName')
        if ($participantNameInput.length) {
            $participantNameInput.val(e.params.data.text);
        }
    });

    $('.single-video-uploader').fileUploader({
        url: "/umbraco/surface/FileUploadSurface/Video/",
        maxFilesize: 250,
        acceptedFiles: 'video/*',
        removeUrl: "/umbraco/surface/FileUploadSurface/RemoveVideo/",
        maxFiles: 1,
        successCallback: function (file, result) {
            if (result.Message != "Error") {
                $.fn.oembedProvider.get($('#UploadVideo_Video'), result.Data, 'GlobalShowing');
                postUploadDraftSave($('.single-video-uploader').closest('form'));
            }
        }
    });

    $('.photo-uploader').fileUploader({
        url: "/umbraco/surface/FileUploadSurface/EntryImage/?EntryId=" + $(".entryid").val(),
        maxFilesize: 100,
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        removeUrl: "/umbraco/surface/FileUploadSurface/RemoveEntryImage/?EntryId=" + $(".entryid").val(),
        currentFilesUrl: "/umbraco/surface/FileUploadSurface/CurrentEntryImages/?EntryId=" + $(".entryid").val(),
        successCallback: function (file, result, index) {
            if (result.Message != "Error") {
                $('#UploadVideo_ImageCollectionCount').val(index);
                $form = $('.photo-uploader').closest('form')
                if ($form[0].action.indexOf('enter-show') >= 0) {
                    postUploadDraftSave($form);
                }
            }
        },
        removeCallback: function (file, data, index) {
            if (data) {
                if (index == 0) {
                    $('#UploadVideo_ImageCollectionCount').val('');
                }
                else {
                    $('#UploadVideo_ImageCollectionCount').val(index);
                }
            }
        }
    });

    function postUploadDraftSave($form) {
        $.ajax({
            type: 'POST',
            url: '/umbraco/surface/entrysurface/HandleEnterEventFormDraft/',
            data: $form.serializeArray(),
            success: function (result) {
                if (!result.success) {
                    console.log(result.Message);
                }
            }
        });
    }

    $('.onchangesubmit').change(function () {
        window.onbeforeunload = null;
        $(this).closest('form').submit();
    });

    $('.entry-attachment-uploader').fileUploader({
        url: "/umbraco/surface/FileUploadSurface/EntryAttachment/?EntryId=" + $('.entryid').val(),
        maxFilesize: 25,
        addRemoveLinks: true,
        removeUrl: "/umbraco/surface/FileUploadSurface/RemoveEntryAttachment/?EntryId=" + $('.entryid').val(),
        currentFilesUrl: "/umbraco/surface/FileUploadSurface/CurrentEntryAttachments/?EntryId=" + $(".entryid").val(),
    });

    $("#add-team").on("click", function (e) {
        e.preventDefault();
        var newStateVal = $("#new-team").val();
        // Set the value, creating a new option if necessary
        var existingOption = $("#UploadVideo_TeamId").find('option[value="' + newStateVal + '"]');
        if (existingOption.length) {
            existingOption.remove();
        }
        // Create the DOM option that is pre-selected by default
        var newState = new Option(newStateVal, newStateVal, true, true);
        // Append it to the select
        $("#UploadVideo_TeamId").append(newState).trigger('change');
    });

    $('.nosubmitonenter').on("keypress", ":input:not(textarea)", function (event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            return false;
        }
    });

    $('.renumber').reNumber({
        updateElementSelector: '.renumber-number',
        triggerElementSelector: '.renumber-trigger'
    });
}