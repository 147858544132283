$('.entry-card figcaption').each(function () {
    $('.entry-card img').one('load', function () {
        var width = $(this).css('width');
        $(this).parent().next().css('width', width);
    }).each(function () {
        if (this.complete) $(this).load();
    });
});
$(window).resize(function () {
    $('.entry-card img').one('load', function () {
        var width = $(this).css('width');
        $(this).parent().next().css('width', width);
    }).each(function () {
        if (this.complete) $(this).load();
    });
});