
//return parsed input value or new array if the parse fails or the input is empty
function getCropsFromInput(inputId) {
    return $('#' + inputId).val() ? JSON.parse($('#' + inputId).val().replace(/\\/g, '')) || new Array() : new Array();
}

//Update the stored crop type within the input or append if a new crop type is being set
function addCropToInput(inputId, crop) {
    var crops = getCropsFromInput(inputId);
    var found = false;
    for (var i = 0; i < crops.length; i++) {
        if (crops[i].Type === crop.Type) {
            crops[i].QueryString = crop.QueryString
            found = true;
            break;
        }
    }
    if (!found) {
        crops.push(crop);
    }
    $('#' + inputId).val(JSON.stringify(crops))
}