(function ($) {

    $.fn.ajaxPaging = function () {
        return this.each(function () {
            var $that = $(this);
            $that.on('click', '.pagination a', function (e) {
                e.preventDefault();
                $that.load($(this).attr('href'));
            });
        });
    };

}(jQuery));