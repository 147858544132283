(function ($) {
    function updateDisplay(element, formGroupAddClass, formGroupRemoveClass) {
        var $element = $(element);
        var $formGroup = $element.closest('.form-group');
        var $tabPane = $element.closest('.tab-pane');

        $formGroup.addClass(formGroupAddClass).removeClass(formGroupRemoveClass);

        if (formGroupAddClass === 'has-error' && $tabPane.length) {
            var $navTab = $('a[href=\'#' + $tabPane[0].id + '\']').closest('li');

            if (!$tabPane.hasClass('active')) {
                $('.tab-pane').removeClass('active');
                $tabPane.addClass('active');
            }

            if (!$navTab.hasClass('active')) {
                $('.nav-tabs > li').removeClass('active');
                $navTab.addClass('active tab-has-error');
            }

            if (!$navTab.children('a').children('span').hasClass('glyphicon-warning-sign')) {
                var warnGlyphicon = document.createElement('span');
                warnGlyphicon.className += ' glyphicon glyphicon-warning-sign text-danger';
                $navTab.children('a').first().text(' ' + $navTab.children('a').first().text());
                $navTab.children('a').first().prepend(warnGlyphicon);
            }
        }
    }

    $.validator.setDefaults({
        highlight: function (element, errorClass, validClass) {
            updateDisplay(element, errorClass, validClass);
        },
        unhighlight: function (element, errorClass, validClass) {
            updateDisplay(element, validClass, errorClass);
        },
        ignore: []
    });

    $.validator.unobtrusive.options = {
        errorClass: 'has-error',
        validClass: 'has-success'
    };
})(jQuery);