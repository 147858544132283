// Define some variables used to remember state.
var playlistId, nextPageToken, prevPageToken;

// After the API loads, call a function to get the uploads playlist ID.
function handleAPILoaded() {
    requestUserUploadsPlaylistId();
}

// Call the Data API to retrieve the playlist ID that uniquely identifies the
// list of videos uploaded to the currently authenticated user's channel.
function requestUserUploadsPlaylistId() {
    // See https://developers.google.com/youtube/v3/docs/channels/list
    var request = gapi.client.youtube.channels.list({
        mine: true,
        part: 'contentDetails'
    });
    request.execute(function (response) {
        var contentDetails = response.result.items[0].contentDetails;
        var hasAccount = true; //contentDetails.hasOwnProperty('googlePlusUserId');
        if (hasAccount) {
            playlistId = contentDetails.relatedPlaylists.uploads;
            $('#next-button').click(nextPage);
            $('#prev-button').click(previousPage);
            requestVideoPlaylist(playlistId);
        }
        else {
            $('#video-container').html('<p>You do not have a YouTube channel. <a href="https://www.youtube.com/create_channel">Please activate it.</a></p>');
            $('#next-button').css('visibility', 'hidden');
            $('#prev-button').css('visibility', 'hidden');
        }
    });
}

// Retrieve the list of videos in the specified playlist.
function requestVideoPlaylist(playlistId, pageToken) {
    $('#video-container').html('');
    var requestOptions = {
        playlistId: playlistId,
        part: 'snippet,status',
        maxResults: 7
    };
    if (pageToken) {
        requestOptions.pageToken = pageToken;
    }
    var request = gapi.client.youtube.playlistItems.list(requestOptions);
    request.execute(function (response) {
        // Only show pagination buttons if there is a pagination token for the
        // next or previous page of results.
        nextPageToken = response.result.nextPageToken;
        var nextVis = nextPageToken ? 'visible' : 'hidden';
        $('#next-button').css('visibility', nextVis);
        prevPageToken = response.result.prevPageToken
        var prevVis = prevPageToken ? 'visible' : 'hidden';
        $('#prev-button').css('visibility', prevVis);

        var playlistItems = response.result.items;
        if (playlistItems) {
            $.each(playlistItems, function (index, item) {
                displayResult(item.snippet);
            });
        }
        $('[data-video-id]').on('click', selectId);
        $('#video-container').append('<div class="video-thumbnail"><a href="http://www.youtube.com/upload" data-add-video><img src="/images/addvideo.png" /></a><p><a href="http://www.youtube.com/upload" data-add-video>Upload Video</a></p></div>');
        //$('[data-add-video]').on('click', function (e) {
        //    e.preventDefault();
        //    var r = confirm("Open YouTube to upload video?");
        //    if (r)

        //    $('.upload-video-youtube').show();
        //});
    });
}

// Create a listing for a video.
function displayResult(videoSnippet) {
    var title = videoSnippet.title;
    var videoId = videoSnippet.resourceId.videoId;
    var image = videoSnippet.thumbnails.default.url;
    $('#video-container').append('<div class="video-thumbnail"><a href="#" data-video-id="' + videoId + '"><img src="' + image + '" /></a><p><a href="#" data-video-id="' + videoId + '">' + title + '</a></p></div>');
}

function selectId(e) {
    e.preventDefault();
    var $this = $(this);
    $this.parent().siblings('.selected').removeClass('selected');
    $this.parent().addClass("selected");
    var data = $this.data();

    var requestOptions = {
        part: 'player,status',
        id: data.videoId
    };

    var request = gapi.client.youtube.videos.list(requestOptions);
    request.execute(function (response) {
        var $video = $('#UploadVideo_Video');
        var $validationSpan = $('[data-valmsg-for="' + $video.attr('name') + '"]');
        try {
            var iframe = response.result.items[0].player.embedHtml;
            var $iframe = $(iframe).attr('width', 750).attr('height', 422);
            $video.val(encodeURIComponent($iframe.prop('outerHTML'))).trigger('change');
            //if (response.result.items[0].status.privacyStatus == "private") {
            //    $validationSpan.html('<span id="UploadVideo_Video-error" class="text-warning">This is a private video, please ensure it is shared with judges@globalshowing.co.uk to allow judging</span>');
            //}
            //else {
            //    $validationSpan.html('');
            //}
        }
        catch (e) {
            $validationSpan.html('<span id="UploadVideo_Video-error" class="text-danger">There is an error with this Video, please select another</span>');
            $video.val('').trigger('change');
        }
    });
}

// Retrieve the next page of videos in the playlist.
function nextPage(e) {
    e.preventDefault();
    requestVideoPlaylist(playlistId, nextPageToken);
}

// Retrieve the previous page of videos in the playlist.
function previousPage(e) {
    e.preventDefault();
    requestVideoPlaylist(playlistId, prevPageToken);
}