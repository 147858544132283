var initPhotoSwipeFromDOM = function (gallerySelector) {
    var $galleryElements = $(gallerySelector);
    if ($galleryElements.length > 0) {
        // parse slide data (url, title, size ...) from DOM elements
        // (children of $galleryElements)
        var parseThumbnailElements = function (el) {
            var thumbElements = el.querySelectorAll(".news-wrapper figure");
            var numNodes = thumbElements.length;
            var items = [];
            var figureEl;
            var linkEl;
            var item;

            for (var i = 0; i < numNodes; i++) {


                figureEl = thumbElements[i]; // <figure> element

                // include only element nodes
                if (figureEl.nodeType !== 1) {
                    continue;
                }

                // linkEl = figureEl.children[0]; // <a> element
                // fix to select only the first anchor tag which is a gallery link
                linkEl = figureEl.querySelectorAll("a[itemprop]")[0];

                // size = linkEl.getAttribute("data-size").split("x");

                // create slide object
                item = {
                    src: linkEl.getAttribute("href"),
                    // w: parseInt(size[0], 10),
                    // h: parseInt(size[1], 10)
                    w: 0,
                    h: 0
                };

                if (figureEl.children.length > 1) {
                    // <figcaption> content
                    // item.title = figureEl.children[1].innerHTML;
                    // fix to only select the first figcaption element
                    item.title = figureEl.getElementsByTagName("figcaption")[0].innerHTML;
                }

                if (linkEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = linkEl.children[0].getAttribute("src");
                }

                item.el = figureEl; // save link to element for getThumbBoundsFn
                items.push(item);
            }

            return items;
        };

        // find nearest parent element
        var closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
        };

        // triggers when user clicks on thumbnail
        var onThumbnailsClick = function (e) {
            e = e || window.event;
            e.preventDefault ? e.preventDefault() : e.returnValue = false;

            var eTarget = e.target || e.srcElement;

            var clickedListItem = closest(eTarget, function (el) {
                return (el.tagName && el.tagName.toUpperCase() === "FIGURE");
            });

            if (!clickedListItem) {
                return;
            }


            // find index of clicked item
            var clickedGallery = clickedListItem.parentNode.closest('.gallery');
            var childNodes = clickedGallery.getElementsByTagName('figure');
            var numChildNodes = childNodes.length;
            var nodeIndex = 0;
            var index;

            for (var i = 0; i < numChildNodes; i++) {
                if (childNodes[i].nodeType !== 1) {
                    continue;
                }

                if (childNodes[i] === clickedListItem) {
                    index = nodeIndex;
                    break;
                }
                nodeIndex++;
            }



            if (index >= 0) {
                openPhotoSwipe(index, clickedGallery, 1, e.target.parentNode);
            }
            return false;
        };

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        var photoswipeParseHash = function () {
            var hash = window.location.hash.substring(1);
            var params = {};

            if (hash.length < 5) {
                return params;
            }

            var vars = hash.split("&");
            for (var i = 0; i < vars.length; i++) {
                if (!vars[i]) {
                    continue;
                }
                var pair = vars[i].split("=");
                if (pair.length < 2) {
                    continue;
                }
                params[pair[0]] = pair[1];
            }

            if (params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            if (!params.hasOwnProperty("pid")) {
                return params;
            }
            params.pid = parseInt(params.pid, 10);
            return params;
        };

        var openPhotoSwipe = function (index, galleryElement, disableAnimation, guidElement) {
            var pswpElement = document.querySelectorAll(".pswp")[0];
            var gallery;
            var options;
            var items;
            disableAnimation = disableAnimation || 1;

            items = parseThumbnailElements(galleryElement.closest('.gallery'));

            // define options (if needed)
            options = {
                index: index,

                // define gallery index (for URL)
                galleryUID: guidElement.getAttribute("data-pswp-uid"),

                getTextForShare: function (shareButtonData) {
                    return guidElement.parentElement.lastElementChild.innerText.trim().replace(/(\r\n|\n|\r)/gm, " ") || '';
                },

            };

            if (disableAnimation) {
                options.showAnimationDuration = 0;
            }
            // Pass data to PhotoSwipe and initialize it
            var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.listen("gettingData", function (index, item) {
                if (item.w < 1 || item.h < 1) { // unknown size
                    var img = new Image();
                    img.onload = function () { // will get size after load
                        item.w = this.width; // set image width
                        item.h = this.height; // set image height
                        gallery.invalidateCurrItems(); // reinit Items
                        gallery.updateSize(true); // reinit Items
                    }
                    img.src = item.src; // let"s download image
                }
            });
            gallery.init();
        }


        for (var i = 0, l = $galleryElements.length; i < l; i++) {
            $galleryElements[i].setAttribute("data-pswp-uid", i + 1);
            $galleryElements[i].onclick = onThumbnailsClick;
        }

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        var hashData = photoswipeParseHash();
        if (hashData.pid > 0 && hashData.gid > 0) {
            openPhotoSwipe(hashData.pid - 1, $galleryElements[hashData.gid - 1], true, $galleryElements[hashData.gid - 1]);
        }
    }
};

function CopyResultsTextToClipboard() {
    var targetElements = document.getElementsByClassName("results-text-target");

    if (targetElements.length) {
        var outputText = "Class - ";
        for (var i = 0; i < targetElements.length; i++) {
            outputText += targetElements[i].innerText;
            outputText += "\n";
        }
        var tempInput = document.createElement("textarea");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = outputText;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }
};