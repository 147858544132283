function handleBasketQuantityChange(e) {  
    postBasketQuantity(e.target.id.match(/^[^-]*-(.*)$/)[1], e.params.data.text)
}

function handleBasketDelete(e) {
    postBasketQuantity(e.currentTarget.id.match(/^[^-]*-(.*)$/)[1], 0);
}


function postBasketQuantity(id, quantity) {
    if (/^\d*$/.test(quantity) && quantity < 100) {
        $.ajax({
            type: 'POST',
            url: '/umbraco/surface/basketsurface/setquantity/',
            data: {
                Id: id,
                Quantity: quantity
            },
            success: function (result) {
                $('.subtotal-value').each(function (index, element) {
                    $(element).text(result.SubTotal);
                });
                $('.total-items-value').each(function (index, element) {
                    $(element).text(result.TotalItems);
                    $('.basket-total-header').text(result.TotalItems);
                });
                $('.basket-line-item').each(function (index, element) {
                    if (!(result.ItemIds.indexOf(element.id) > -1)) {
                        $(element).fadeOut(500, function () {
                            $(this).remove();
                        });
                    }
                });
                if (result.ItemIds.length < 1) {
                    window.location = window.location.href.split('?')[0];
                }
            }
        });
    } else {
        console.log('given quantity was not a whole integer or was larger than 99');
    }
}