(function ($) {
    $.fn.oembedProvider = function (target) {
        $target = $(target);
        return this.each(function () {
            $this = $(this);
            var data = $this.data();
            var button = data.button;
            var provider = data.provider;
            if (button)
                $(button).on('click', function (e) {
                    e.preventDefault();
                    var url = $this.val();
                    $.fn.oembedProvider.get($target, url, provider);
                });
            $this.on('blur', function () {
                var url = $(this).val();
                $.fn.oembedProvider.get($target, url, provider);
            });
        });
    };

    $.fn.oembedProvider.get = function ($target, url, provider) {
        $.get('/umbraco/api/OEmbedApi/GetEmbed', { url: url, width: 750, height: 422, provider: provider }, function (data) {
            var $validationSpan = $('[data-valmsg-for="' + $target.attr('name') + '"]');
            if (data.Status == 0 || data.Status == 'NotSupported')
            {
                $validationSpan.html('<span id="UploadVideo_Video-error" class="text-danger">This URL is not supported</span>');
                $target.val('').trigger('change');
            }
            else if (data.Status == 1 || data.Status == 'Error')
            {
                $validationSpan.html('<span id="UploadVideo_Video-error" class="text-danger">There is an error with this Video, please select another</span>');
                $target.val('').trigger('change');
            }
            else {
                $validationSpan.html('');
                $target.val(encodeURIComponent(data.Markup)).trigger('change');
            }
        });
    }
}(jQuery));
