function initialiseCroppieIfPresent() {
    if ($('#edit-image-page').length) {

        $('#edit-image-page').height($('#edit-image-page>div').outerHeight());
        var windowHeight = $(window).height();
        var windowWidth = $(window).width();
        var rotate = 0;
        var mobile = 0;
        var placeholder = placeholder;
        var type = 'all';
        var basic = $('#basic').croppie({
            viewport: {
                width: 514.5,
                height: 385.50
            },
            enableResize: true,
            enableOrientation: true,
            enforceBoundary: true,
        });
            basic.croppie('bind', {
                url: imagePath,
                points: [0, 0, 514.5, 385.50],
            }).then(function () {
                basic.croppie('setZoom', 0);
                if (windowWidth <= 630) {
                    $('#edit-image-page').height($('#edit-image-page>div').outerHeight());
                    mobile = 1;
                    setViewPortSize(mobile);
                    basic.croppie('bind', {
                        url: imagePath,
                    }).then(function () {
                        basic.croppie('setZoom', '0.1');
                    });
                } else if (windowWidth > 630) {
                    mobile = 0;
                    setViewPortSize(mobile);
                    basic.croppie('bind', {
                        url: imagePath,
                    }).then(function () {
                        basic.croppie('setZoom', '0.1');
                    });
                };
            });
            $('.cr-resizer-vertical, .cr-resizer-horisontal').css('cursor', 'pointer');

        // Determine which viewport to display based on screen size
        function setViewPortSize(mobile) {
            $('#basic').val('');
            if (mobile != 1) {
                $('#basic').css('width', "600px");
                $('#basic').css('height', "600px");
                $('.cr-resizer').css({ 'width': '514.5px', 'height': '385.50px' });
                $('.cr-viewport').css({ 'width': '514.5px', 'height': '385.50px' });
            } else {
                $('#basic').css('width', "300px");
                $('#basic').css('height', "300px");
                $('.cr-resizer').css({ 'width': '257.25px', 'height': '192.75px' });
                $('.cr-viewport').css({ 'width': '257.25px', 'height': '192.75px' });
            }
        };

        // Check whether the placeholder should be used
        function checkForPlaceholder(placeholder) {
            if (placeholder == false) {
                basic.croppie('bind', {
                    url: imagePath,
                }).then(function () {
                    basic.croppie('setZoom', '0.1');
                });
            } else if (placeholder == true) {
                basic.croppie('bind', {
                    url: 'url(/media/1810/img_0695.jpg',
                }).then(function () {
                    basic.croppie('setZoom', '0.1');
                });
            };
        };

        // Rotate the image anti-clockwise
        $("#RotateAntiClockwise").on("click", function () {
            basic.croppie('rotate', 90);
            rotate = rotate - 90;
        });

        // Rotate the image clockwise
        $("#RotateClockwise").on("click", function () {
            basic.croppie('rotate', -90);
            rotate = rotate + 90;
        });

        // Accept the crop, update the previews
        $("#AcceptCropButton").on("click", function () {
            basic.croppie("result", {
                type: "blob",
                size: "viewport"
            });
            cropInfo = basic.croppie('get');
            if (type == "og" || type == "en") {
                query = "?rotate=" +
                    rotate +
                    "&crop=" +
                    (cropInfo.points[0]) +
                    "," +
                    (cropInfo.points[1]) +
                    "," +
                    ((cropInfo.points[2]) - (cropInfo.points[0])) +
                    "," +
                    ((cropInfo.points[3]) - (cropInfo.points[1]));
            } else {
                query = "?rotate=" +
                    rotate +
                    "&crop=" +
                    (cropInfo.points[0]) +
                    "," +
                    (cropInfo.points[1]) +
                    "," +
                    ((cropInfo.points[2]) - (cropInfo.points[0])) +
                    "," +
                    ((cropInfo.points[3]) - (cropInfo.points[1]) + "&width=514.5&height=385.50&bgcolor=fff");
            }
            if (type == "all") {
                addCropToInput('CropsJson', { QueryString: query, Type: "sp" });
                addCropToInput('CropsJson', { QueryString: query, Type: "lg" });
                addCropToInput('CropsJson', { QueryString: query, Type: "md" });
                addCropToInput('CropsJson', { QueryString: query, Type: "sm" });
                addCropToInput('CropsJson', { QueryString: query, Type: "og" });
                addCropToInput('CropsJson', { QueryString: query.replace("&width=514.5&height=385.50&bgcolor=fff",''), Type: "en" });
            } else {
                addCropToInput('CropsJson', { QueryString: query, Type: type });
            };
            if (!$('#usePlaceholder').checked) {
                $('.chosen div').css('background-image', "url(" + imagePath + query + (query.indexOf("&bgcolor") == -1 ? "&width=514.5&height=385.50&bgcolor=fff" : "") + ")")
            } else {
                $('.chosen div').css('background-image', "url(/media/1810/img_0695.jpg" + query + ")")
            };
        });

        // Update previews with placeholder
        $('#usePlaceholder').on('change', function () {
            if (!$('#usePlaceholder').prop('checked')) {
                $('#basic').val('');
                basic.croppie('bind', {
                    url: imagePath
                }).then(function () {
                    basic.croppie('setZoom', '0.1');
                });
                $('ul.previews li ').addClass('chosen');
                $('ul.previews li div').css('background-image', "url(" + imagePath + "?quality=60&width=514.5&height=385.5&mode=crop)");
                $('#basic').css('background-image', "none");
                $('.cr-boundary, .cr-slider-wrap').css('visibility', 'visible');
                $('#RotateAntiClockwise, #RotateClockwise, #AcceptCropButton').css('display', 'inline-block');
            } else {
                rotate = 0;
                $('#image-size').text('All Selected');
                $('ul.previews li ').addClass('chosen');
                type == "all"
                $('#basic').val('');
                basic.croppie('bind', {
                    url: '/media/1810/img_0695.jpg'
                });
                $('ul.previews li div, #basic').css('background-image', "url(/media/1810/img_0695.jpg)");
                $('ul.previews li div').css('background-image', "url(/media/1810/img_0695.jpg?width=514.5&crop=25,100,514.5,385.50)");
                $('.cr-boundary, .cr-slider-wrap').css('visibility', 'hidden');
                $('#RotateAntiClockwise, #RotateClockwise, #AcceptCropButton').css('display', 'none');
            };
        });

        // Reset all crops
        $('#reset').on('click', function () {
            $('#image-size').text('All Selected')
            $('#basic').val('');
            basic.croppie('bind', {
                url: imagePath
            }).then(function () {
                basic.croppie('setZoom', '0.1');
            });
            $('ul.previews li div').css('background-image', "url(" + imagePath + "?quality=60&width=514.5&height=385.5&mode=crop)");
            $('ul.previews li ').addClass('chosen');
            defaultCrops.map(function (x) { addCropToInput('CropsJson', x) });
            type == "all"
            rotate = 0;
        });

        // Changing the crop type
        $('#edit-image-page').on('click', 'ul.previews li', function () {
            $('#selectAll').prop('checked', false);
            $('ul.previews li').removeClass('chosen');
            $(this).addClass('chosen');
            if ($(this).attr('id') == 'og') {
                type = 'og';
                $('#image-size').text('Gallery / Thumbanil')
                checkForPlaceholder(placeholder);
                setViewPortSize(mobile);
            }
            else if ($(this).attr('id') == 'sp') {
                type = 'sp';
                $('#image-size').text('Champion');
                if (placeholder == false) {
                    basic.croppie('bind', {
                        url: imagePath,
                    }).then(function () {
                        basic.croppie('setZoom', '0.1');
                    });
                } else if (placeholder == true) {
                    basic.croppie('bind', {
                        url: 'url(/media/1810/img_0695.jpg',
                    }).then(function () {
                        basic.croppie('setZoom', '0.1');
                    });
                };
                setViewPortSize(mobile);
            } else if ($(this).attr('id') == 'lg') {
                type = 'lg';
                $('#image-size').text('First Place');
                checkForPlaceholder(placeholder);
                setViewPortSize(mobile);
            } else if ($(this).attr('id') == 'md') {
                type = 'md';
                $('#image-size').text('Second and Third Place');
                checkForPlaceholder(placeholder);
                setViewPortSize(mobile);
            } else if ($(this).attr('id') == 'sm') {
                type = 'sm';
                $('#image-size').text('4th Place and Above');
                checkForPlaceholder(placeholder);
                setViewPortSize(mobile);
            } else if ($(this).attr('id') == 'en') {
                type = 'en';
                $('#image-size').text('Entry');
                checkForPlaceholder(placeholder);
                setViewPortSize(mobile);
            };
            var cropInfo = basic.croppie('get');
        });

        // Check for winddow size increase to increase container
        $(window).on('resize', function () {
            var win = $(this);
            if (win.width() <= 630) {
                mobile = 1;
                setViewPortSize(mobile);
                basic.croppie('bind', {
                    url: imagePath,
                }).then(function () {
                    basic.croppie('setZoom', '0.1');
                    });
            } else if (win.width() > 630) {
                mobile = 0;
                setViewPortSize(mobile);
                basic.croppie('bind', {
                    url: imagePath,
                }).then(function () {
                    basic.croppie('setZoom', '0.1');
                    });
            }
        });
    }
}
