(function ($) {

    $.fn.modalCrud = function () {
        return this.each(function () {
            var $that = $(this);
            $that.on('click', 'a.crud', function (e) {
                e.preventDefault();
                $(this).off('click');
                $(this).click(function (e) {
                    e.preventDefault();
                });
                $.get($(this).attr('href'), setUpForm);
            });
        });
    };

    var setUpForm = function (html) {
        if (html.hasOwnProperty('result'))
            window.location = html.result;
        var $html = $(html);
        $('body').append($html);
        $html.modal();
        $html.on('shown.bs.modal', function () {
            initialisers($html);
            //todo: fix validation for modals
            //$form = $html.find('form');
            //$form.validate();
            //$form.on('submit', function (e) {
            //    e.preventDefault();
            //    if ($form.valid()) {
            //        $form.off('submit');
            //        $form.submit();
            //    }
            //});
        });
        $html.on('hidden.bs.modal', function () {
            $(this).remove();
        });
    };

}(jQuery));