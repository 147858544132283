(function ($) {

    $.fn.scrollToTab = function () {
        return this.each(function () {
            $(this).find('a[data-toggle="tab"]').on('show.bs.tab', function () {
                $($(this).attr('href')).parent().show();
            }).on('shown.bs.tab', function (e) {
                $.scrollTo( $(this).attr('href'), 1000, { offset: -20 });
            });

        });
    };

}(jQuery));